import { ComponentProps } from 'lib/component-props';
import { DropdownItem } from '../components/inline-heading-and-dropdown/dropdown';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import Spinner from 'components/spinner/Spinner';
import { useEffect, useMemo, useState } from 'react';
import MultiProductTableCard from '../components/multi-tier-pricing-card/MultiProductTableCard';
import { useSearchParams } from 'next/navigation';
import { MultiProductTableCardItem } from '../components/multi-tier-pricing-card/multi-product-table-card-item';
import {
  Field,
  GetStaticComponentProps,
  LinkField,
  RichText,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { useI18n } from 'next-localization';
import BadgeSection from '../components/multi-tier-pricing-card/BadgeSection';
import SecondaryCtaSection from '../components/multi-tier-pricing-card/SecondaryCtaSection';
import MultiProductTableCarousel from '../components/multi-tier-pricing-card/MultiProductTableCarousel';
import InlineHeadingAndDropdown from '../components/inline-heading-and-dropdown/InlineHeadingAndDropdown';
import { ProductVariationsData, Variant } from 'lib/commerce/product/model/product-models';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import {
  AnalyticsProductItem,
  getProductCartIndex,
  pushAddToCart,
  pushSelectItem,
  pushViewItemList,
  pushViewItems,
} from 'lib/google-analytics/commerce';
import { useCartData } from 'components/cart/cart-hooks';
import { MultiTierPricingTableResponse } from '../components/multi-tier-pricing-card/multi-tier-pricing-table-response';
import { GraphQLClient } from 'lib/common/graphql-client';
//import { MultiTierPricingTableQueryDocument } from './graphql/multi-tier-pricing-table-query.graphql';
import { MultiTierPricingTableCardResponse } from '../components/multi-tier-pricing-card/multi-tier-pricing-table-card-response';
//import { MultiTierPricingTableCardQueryDocument } from './graphql/multi-tier-pricing-table-card-query.graphql';
import getMultiTierPricingTableCardQuery from '../components/multi-tier-pricing-card/multi-tier-pricing-table-card-query';
import getMultiTierPricingTableQuery from '../components/multi-tier-pricing-card/multi-tier-pricing-table-query';
import { MapToProps } from '../components/multi-tier-pricing-card/mapping-util';
import AlertBox from 'components/alert/AlertBox';
import { pushProductLoad } from 'lib/google-analytics/product-load';
import { getProductTierDetails } from 'lib/commerce/product/product-api';
import { isNonOkResponse } from 'lib/common/common-http-client';
import { getWebstore } from 'lib/commerce/webstore/webstore-api';
import { getStoredCurrency } from 'components/cart/cart-provider';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { addCartItem } from 'components/cart/cart-slice';
import { useRouter } from 'next/router';
import { themeColors } from 'components/foundation/non-sitecore/themes/themes-options';

type MultiTierPricingCardProps = ComponentProps & {
  initialProductTierDetails: Array<ProductVariationsData & { currency: string }>;
  mappedData: MultiTierPricingCardPropFields;
  mini: boolean;
  allTierData: AllProductVariationsListItem[];
};

export type MultiTierPricingCardPropFields = {
  productTableName: string;
  productTableId: Field<string>;
  cards: Array<MultiProductTableCardItem>;
  footerContent: Field<string>;
  link: LinkField;
  badCombinationMessage: Field<string>;
  frequencyTypeOptions: Array<DropdownItem>;
  defaultFrequencyType: Array<DropdownItem>;
  userTypeOptions: Array<DropdownItem>;
  defaultUserType: Array<DropdownItem>;
};

export type AllProductVariationsListItem = {
  userType: VariationTypeItem;
  frequencyType: VariationTypeItem;
  variants: (ProductVariationsData & {
    currency: string;
  })[];
};

type VariationTypeItem = {
  title: {
    jsonValue: Field<string>;
  };
  value: {
    jsonValue: Field<string>;
  };
};
const cartPagePath = '/cart';
const colorClasses = themeColors.white;
const mapVariantIdToCard = (
  productTierDetails: ProductVariationsData,
  cards: Array<MultiProductTableCardItem>
): Map<string, MultiProductTableCardItem> => {
  const productIdToCard = new Map<string, MultiProductTableCardItem>();
  for (let i = 0; i < productTierDetails.variants.length; i++) {
    const productId = productTierDetails.variants[i].productId;
    const card = cards.filter((x) => x.fields.product[0].fields.productId.value == productId);

    if (card == null || card.length == 0) {
      continue;
    }

    productIdToCard.set(productTierDetails.variants[i].productId, card[0]);
  }

  return productIdToCard;
};

const MultiTierPricingCard = (props: MultiTierPricingCardProps): JSX.Element => {
  const { t } = useI18n();
  const productTierDetailsData = useAppSelector((state) => state.product.productTierDetailsData);
  const productTierDetailsStatus = useAppSelector((state) => state.product.productTierDetailStatus);
  const searchParams = useSearchParams();
  const searchParamUserType = searchParams.get('show');
  const [selectedUserType, setSelectedUserType] = useState(
    props.mappedData?.defaultUserType[0].fields.value.value
  );

  const [selectedFrequencyType, setSelectedFrequencyType] = useState(
    props.mappedData?.defaultFrequencyType[0].fields.value.value
  );

  const [visibleCardHasSubLink, setVisibleCardHasSubLink] = useState(false);
  const [visibleCardHasBadge, setVisibleCardHasBadge] = useState(false);

  const storedCurrency = getStoredCurrency();
  const [productTierDetails, setProductTierDetails] = useState<ProductVariationsData | null>(
    props.initialProductTierDetails?.filter(
      (x) => x.currency == (storedCurrency.length ? storedCurrency : 'USD')
    )[0]
  );

  const [productIdsToCards, setProductIdsToCards] = useState<Map<
    string,
    MultiProductTableCardItem
  > | null>(
    productTierDetails != null
      ? mapVariantIdToCard(productTierDetails, props.mappedData.cards)
      : null
  );
  const [productTierDetailsToDisplay, setProductTierDetailsToDisplay] = useState<Array<Variant>>(
    productTierDetails != null && productIdsToCards != null
      ? productTierDetails.variants.filter((variant) => {
          return productIdsToCards.has(variant.productId);
        })
      : []
  );

  const editorActive = isEditorActive();
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const { data: session } = useSession();
  const dispatch = useAppDispatch();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const router = useRouter();

  const [usingInitialData, setUsingInitialData] = useState(productTierDetailsToDisplay.length > 0);
  const cart = useCartData();
  const newCart = useAppSelector((state) => state.cart);
  const cartAddItemStatus = useAppSelector((state) => state.cart.cartAddItemStatus);
  const listId = `MultiTierPricingCard_${props.rendering.uid}`;
  const listName = 'Multi-tier Pricing Card';

  const onClickAddToCartHandler = (variant): void => {
    if (currencyIsoCode == null) {
      return;
    }
    if (newCart?.cartSummaryData == null) {
      return;
    }
    const quantity = 1;

    const index = getProductCartIndex(variant.sku, newCart.cartItemsData);
    currencyIsoCode &&
      pushAddToCart(
        currencyIsoCode,
        (
          parseFloat(newCart.cartSummaryData.totalProductAmountAfterAdjustments) +
          variant.price.unitPrice * quantity
        ).toString(),
        {
          id: variant.productId,
          sku: variant.productId,
          name: variant.name,
          brand: variant.brand,
          price: variant.price.unitPrice.toString(),
          discount: (variant.price.listPrice - variant.price.unitPrice).toString(),
          variant: variant.sku,
          quantity: quantity.toString(),
          index: index.toString(),
          itemCategory: variant.productCategoryPaths?.primary?.at(-1)?.name,
          itemCategory2: variant.productCategoryPaths?.path1?.at(-1)?.name,
          itemCategory3: variant.productCategoryPaths?.path2?.at(-1)?.name,
          itemCategory4: variant.productCategoryPaths?.path3?.at(-1)?.name,
          productSku: variant.sku,
        }
      );

    dispatch(addCartItem({ sku: variant.productId, quantity, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        router.push(cartPagePath);
      });
  };

  useEffect(() => {
    const currencySpecificData = props.initialProductTierDetails?.filter(
      (x) => x.currency == currencyIsoCode
    )[0];

    if (currencySpecificData == null) {
      setUsingInitialData(false);
      return;
    }

    setProductTierDetails(currencySpecificData);
    setUsingInitialData(true);
  }, [currencyIsoCode, props.initialProductTierDetails]);

  useEffect(() => {
    if (productTierDetailsData == null) {
      return;
    }

    setUsingInitialData(false);
    setProductTierDetails(productTierDetailsData);
  }, [productTierDetailsData]);

  useEffect(() => {
    if (productTierDetails?.variants == null || props.mappedData == null) {
      return;
    }

    const productIdToCard = mapVariantIdToCard(productTierDetails, props.mappedData.cards);
    const filteredProductTierDetailsResults: Variant[] = productTierDetails.variants.filter(
      (variant) => {
        return productIdToCard.has(variant.productId);
      }
    );

    setProductIdsToCards(productIdToCard);
    if (!editorActive) {
      setProductTierDetailsToDisplay(filteredProductTierDetailsResults);
      if (currencyIsoCode == null || cart == null) {
        return;
      }

      const analyticsItems = filteredProductTierDetailsResults.map((variant, index) => {
        return {
          id: variant.productId,
          sku: variant.productId,
          name: variant.name,
          price: variant.price.unitPrice.toString(),
          discount: (variant.price.listPrice - variant.price.unitPrice).toString(),
          brand: variant.brand,
          variant: variant.sku,
          index: index.toString(),
          listName: listName,
          listId: listId,
          itemCategory: variant.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: variant.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: variant.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: variant.productCategoryPaths.path3?.at(-1)?.name,
          productSku: variant.sku,
        };
      });

      pushViewItems(
        currencyIsoCode,
        cart.cartSummary.totalProductAmountAfterAdjustments,
        analyticsItems
      );

      pushViewItemList(listId, listName, currencyIsoCode, analyticsItems);
    } else {
      setProductTierDetailsToDisplay(productTierDetails.variants);
    }
  }, [
    cart,
    currencyIsoCode,
    editorActive,
    listId,
    productTierDetails,
    props.mappedData,
    props.rendering.uid,
  ]);

  useEffect(() => {
    if (productTierDetailsStatus != 'succeeded') {
      return;
    }
    if (productTierDetailsStatus == 'succeeded' && productTierDetailsToDisplay.length == 0) {
      pushProductLoad('products_loaded_fail', {
        product_name: props.mappedData.productTableName,
        product_page_url: window.location.href,
        payment_term: selectedFrequencyType,
        user_group: selectedUserType,
        products_loaded_status: 'fail',
        notification_message: props.mappedData.badCombinationMessage.value,
      });
    } else {
      pushProductLoad('products_loaded_success', {
        product_name: props.mappedData.productTableName,
        product_page_url: window.location.href,
        payment_term: selectedFrequencyType,
        user_group: selectedUserType,
        products_loaded_status: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTierDetailsToDisplay]);

  useEffect(() => {
    if (productIdsToCards == null || productIdsToCards.size == 0) {
      return;
    }

    for (const value of productIdsToCards.values()) {
      if (value.fields.secondaryCta.value.href == null) {
        continue;
      }

      if (value.fields.secondaryCta.value.href.length == 0) {
        continue;
      }

      setVisibleCardHasSubLink(true);
      return;
    }

    setVisibleCardHasSubLink(false);
  }, [productIdsToCards]);

  useEffect(() => {
    if (productIdsToCards == null || productIdsToCards.size == 0) {
      return;
    }

    for (const value of productIdsToCards.values()) {
      if (value.fields.badgeText.value == null) {
        continue;
      }

      if (value.fields.badgeText.value.length == 0) {
        continue;
      }

      setVisibleCardHasBadge(true);
      return;
    }

    setVisibleCardHasBadge(false);
  }, [productIdsToCards]);

  const onClickSelectItemHandler = (variant: Variant, index: number): void => {
    if (currencyIsoCode == null) {
      return;
    }

    const analyticsProduct: AnalyticsProductItem = {
      id: variant.productId,
      sku: variant.productId,
      name: variant.name,
      brand: variant.brand,
      price: variant.price.unitPrice.toString(),
      discount: (variant.price.listPrice - variant.price.unitPrice).toString(),
      variant: variant.sku,
      listId: listId,
      listName: listName,
      index: index.toString(),
      itemCategory: variant.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: variant.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: variant.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: variant.productCategoryPaths.path3?.at(-1)?.name,
      productSku: variant.sku,
    };

    pushSelectItem(listId, listName, currencyIsoCode, [analyticsProduct]);
  };

  const onClickBuyNowHandler = (): void => {
    const section = document.querySelector('#multi-tier-table');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const lowestPriceVariant = useMemo(() => {
    if (productTierDetailsToDisplay.length === 0) {
      return null;
    }

    let lowestPriceProduct: Variant | null = null;
    let lowestPrice = productTierDetailsToDisplay
      .map((variant) => variant.price.unitPrice)
      .sort((a, b) => b - a)[0];

    for (let i = 0; i < productTierDetailsToDisplay.length; i++) {
      if (productTierDetailsToDisplay[i].price.unitPrice == 0) {
        continue;
      }

      if (productTierDetailsToDisplay[i].price.unitPrice <= lowestPrice) {
        lowestPrice = productTierDetailsToDisplay[i].price.unitPrice;
        lowestPriceProduct = productTierDetailsToDisplay[i];
      }
    }

    return lowestPriceProduct;
  }, [productTierDetailsToDisplay]);

  const miniVersion = (): JSX.Element => {
    return (
      <div
        id="multi-tier-table-mini"
        className="m-2 flex justify-between items-center w-full md:w-fit pr-3"
      >
        <div className="flex items-center justify-start space-x-2 w-full md:w-fit">
          {lowestPriceVariant != null && (
            <>
              <div className="hidden lg:block text-base-dark text-[0.8125rem] tracking-[0.2em]">
                <span className="uppercase mr-1">{t('MultiTierPricingCard_StartsAt')}</span>
                {lowestPriceVariant.price.unitPrice != lowestPriceVariant.price.listPrice && (
                  <span className="line-through">
                    {t(`Currency_Symbol_${lowestPriceVariant.price.currencyIsoCode}`)}
                    {lowestPriceVariant.price.listPrice}
                  </span>
                )}
              </div>

              <div className="hidden lg:block text-[1.25rem] tracking-[0.2em]">
                {t(`Currency_Symbol_${lowestPriceVariant?.price.currencyIsoCode}`)}
                {lowestPriceVariant?.price.unitPrice}
              </div>
            </>
          )}

          <div className="text-right flex-grow">
            <button
              className="btn-primary ml-auto px-3 py-1 text-[1rem]"
              id="buy-now-mini-btn"
              onClick={(): void => onClickBuyNowHandler()}
            >
              {t('MultiTierPricingCard_BuyNow')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const badCombination =
    productTierDetailsStatus == 'succeeded' && productTierDetailsToDisplay.length == 0;

  const showSpinner = productTierDetailsStatus != 'succeeded' && !usingInitialData;
  let isSubtitlePresent: boolean | null = null;
  let isListPriceDifferent: boolean | null = null;
  productTierDetailsToDisplay.length > 0 &&
    productIdsToCards !== null &&
    productIdsToCards.size > 0 &&
    productTierDetailsToDisplay.map((variation) => {
      const cards = productIdsToCards.get(variation.productId);
      if (cards?.fields?.description) isSubtitlePresent = true;
      if (variation.price.unitPrice !== variation.price.listPrice) isListPriceDifferent = true;
    });
  return (
    <>
      {props.mini && miniVersion()}
      {!props.mini && (
        <div
          id="multi-tier-table"
          className="mx-auto text-almost-black mb-10 lg:mb-20 max-w-content"
        >
          <div
            className={`mb-10 px-6 xl:px-8 2xl:px-0 relative ${
              badCombination ? '' : 'min-h-[600px]'
            } `}
          >
            <InlineHeadingAndDropdown
              fields={props.mappedData}
              rendering={props.rendering}
              params={props.params}
              allTierData={props.allTierData}
              headerText={t('MultiTierPricingCard_Heading_ChooseVersion')}
              orientation="horizontal"
              passUserTypeToParentFunction={(userType: string): void => {
                setSelectedUserType(userType);
              }}
              passFrequencyTypeToParentFunction={(frequencyType: string): void => {
                setSelectedFrequencyType(frequencyType);
              }}
            />

            {productTierDetailsToDisplay.length > 0 &&
              productIdsToCards !== null &&
              productIdsToCards.size > 0 && (
                <>
                  <MultiProductTableCarousel
                    key={`${selectedFrequencyType}, ${selectedUserType}`}
                    variants={productTierDetailsToDisplay}
                  >
                    {productTierDetailsToDisplay.map((variation, index) => {
                      const card = productIdsToCards.get(variation.productId);

                      if (card == null) {
                        return (
                          <div key={variation.productId} className="editor-message">
                            Failed to find card for {variation.productId}. Ensure you have
                            configured one.
                          </div>
                        );
                      }

                      const enableCart =
                        searchParamUserType &&
                        searchParamUserType == 'addtocart' &&
                        variation.price.unitPrice != 0;
                      const featuredTheme = card.fields.featuredTheme.fields.value.value;
                      const featuredText = card.fields.featuredText;
                      const frequencyLabel = t(
                        `MultiTierPricingCard_Card_Frequency_${variation.subscriptionType.toUpperCase()}`
                      );

                      return (
                        <MultiProductTableCard
                          key={variation.sku}
                          isSubtitlePresent={isSubtitlePresent}
                          isListPriceDifferent={isListPriceDifferent}
                          isEnterprise={card.fields.enterpriseDescription?.value != null}
                          enterpriseDescription={card.fields.enterpriseDescription?.value}
                          totalVariations={productTierDetailsToDisplay.length}
                          title={card.fields.product[0].fields.productName.value}
                          titleTag="h3"
                          unitPrice={variation.price.unitPrice}
                          listPrice={variation.price.listPrice}
                          currencyCode={variation.price.currencyIsoCode}
                          frequencyLabel={frequencyLabel}
                          featuredTheme={featuredTheme}
                          featuredText={featuredText}
                          features={card.fields.features}
                          featuresHeading={card.fields.featuresHeading}
                          subtitle={card.fields.description}
                          badgeSection={
                            <BadgeSection
                              field={card.fields.badgeText}
                              anyVisibleCardHasBadge={visibleCardHasBadge}
                              className="mt-5"
                            />
                          }
                        >
                          <div className="flex flex-col mb-5 relative">
                            {cartAddItemStatus == 'loading' && (
                              <Spinner
                                width={4}
                                margin={3}
                                height={20}
                                color={colorClasses.subscriptionPricingCard.spinnerColor}
                              />
                            )}
                            <button
                              id={`addCartBtn_${variation.productId}`}
                              onClick={(): void => onClickAddToCartHandler(variation)}
                              data-id={variation.productId}
                              className={`buy-now-btn ${
                                featuredText.value && featuredTheme === 'primary'
                                  ? 'btn-feature'
                                  : 'btn-primary'
                              } ${enableCart ? 'block' : 'hidden'} `}
                            >
                              {t('MultiTierPricingCard_AddToCart')}
                            </button>
                            <NextLink
                              id={`buyNowBtn_${variation.productId}`}
                              className={`buy-now-btn ${
                                featuredText.value && featuredTheme === 'primary'
                                  ? 'btn-feature'
                                  : 'btn-primary'
                              } ${enableCart ? 'hidden' : 'block'}`}
                              field={{
                                ...card.fields.primaryCta,
                                value: {
                                  ...card.fields.primaryCta.value,
                                  href:
                                    card.fields.primaryCta.value.href +
                                    '?usertype=' +
                                    selectedUserType,
                                },
                              }}
                              onClick={(): void => onClickSelectItemHandler(variation, index)}
                            ></NextLink>

                            <SecondaryCtaSection
                              field={card.fields.secondaryCta}
                              anyVisibleCardHasSubLink={visibleCardHasSubLink}
                            />
                          </div>
                        </MultiProductTableCard>
                      );
                    })}
                  </MultiProductTableCarousel>

                  <div className="flex justify-center items-center flex-col px-4">
                    <div className="mt-5 text-center small-text">
                      <RichText
                        className="rte text-base-dark [&_a]:underline [&_a]:text-primary [&_a]:hover:text-primary-dark [&_a]:visited:text-purple"
                        field={props.mappedData.footerContent}
                      />
                    </div>
                    <div className="mt-6 sm:mt-12">
                      <NextLink className="btn-outline" field={props.mappedData.link} />
                    </div>
                  </div>
                </>
              )}
            {badCombination && (
              <div className="px-4">
                <AlertBox
                  containerClass="bg-info-light border-info text-info p-4 flex justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 w-fit md:max-w-lg mx-auto"
                  alertMessage={props.mappedData.badCombinationMessage}
                  alertType="info"
                  isDismissible={false}
                  showAlertBox={true}
                  displayIcon={true}
                />
              </div>
            )}
            {showSpinner && <Spinner />}
          </div>
        </div>
      )}
    </>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const language = layoutData.sitecore.context.language;
  const tableResponse = await getMultiTierTableData(rendering.dataSource, language);
  const cards: MultiTierPricingTableCardResponse[] = [];

  for (let i = 0; i < tableResponse?.datasource?.cards?.targetIds.length; i++) {
    const cardData = await getMultiTierTableCardData(
      tableResponse.datasource.cards.targetIds[i],
      language
    );
    cards.push(cardData);
  }

  const defaultFreqType =
    tableResponse.datasource?.defaultFrequencyType?.targetItems[0]?.value.jsonValue.value;
  const defaultUserType =
    tableResponse.datasource?.defaultUserType?.targetItems[0]?.value.jsonValue.value;
  const tableId = tableResponse.datasource?.productTableId?.jsonValue?.value;

  const defaultTierData = await getVariantData(tableId, defaultFreqType, defaultUserType);
  const allTierData = await getAllVariantsData(
    tableId,
    tableResponse.datasource?.userTypeOptions.targetItems,
    tableResponse.datasource?.frequencyTypeOptions.targetItems
  );

  const mappedData = MapToProps(tableResponse, cards);
  const finalData = {
    initialProductTierDetails: defaultTierData,
    mappedData,
    rendering,
    allTierData,
  };

  return finalData;
};

const getVariantData = async (
  tableId: string,
  frequencyType: string,
  userType: string
): Promise<Array<ProductVariationsData & { currency: string }>> => {
  const tierData: Array<ProductVariationsData & { currency: string }> = [];
  if (tableId == null || frequencyType == null || userType == null) {
    return tierData;
  }
  try {
    const currencies = await getCommerceCurrencies();
    for (let i = 0; i < currencies.length; i++) {
      const response = await getProductTierDetails(tableId, frequencyType, userType, currencies[i]);
      if (isNonOkResponse(response)) {
        console.log('Failed to get product tier details', JSON.stringify(response));
      } else {
        tierData.push({
          ...response.data,
          currency: currencies[i],
        });
      }
    }
    return tierData;
  } catch (error) {
    console.log('Failed to get product tier details', error);
    return tierData;
  }
};

const getAllVariantsData = async (
  tableId: string,
  userTypes: VariationTypeItem[],
  frequencyTypes: VariationTypeItem[]
): Promise<AllProductVariationsListItem[]> => {
  const promises: AllProductVariationsListItem[] = [];

  for (const userType of userTypes) {
    for (const frequencyType of frequencyTypes) {
      const variants = await getVariantData(
        tableId,
        frequencyType.value.jsonValue.value,
        userType.value.jsonValue.value
      );
      promises.push({
        userType,
        frequencyType,
        variants,
      });
    }
  }
  return await Promise.all(promises);
};

const getCommerceCurrencies = async (): Promise<Array<string>> => {
  const webStoreData = await getWebstore();
  if (isNonOkResponse(webStoreData)) {
    return ['USD'];
  }

  return webStoreData.data?.supportedCurrencies ?? ['USD'];
};

export const getMultiTierTableData = async (
  datasource: string,
  language: string
): Promise<MultiTierPricingTableResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<MultiTierPricingTableResponse>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMultiTierPricingTableQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export const getMultiTierTableCardData = async (
  datasource: string,
  language: string
): Promise<MultiTierPricingTableCardResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<MultiTierPricingTableCardResponse>(
    getMultiTierPricingTableCardQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default withDatasourceCheck()(MultiTierPricingCard);
